
export const Facebook = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path className={className} fill={color} d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/></svg>
export const Instagram = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path className={className}  fill={color} d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
export const Phone = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path className={className} fill={color} d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
export const Mail = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path className={className} fill={color} d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
export const CaretDown = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path className={className} fill={color} d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
export const CaretLeft = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
    <path className={className} fill={color} d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z"/></svg>
export const CaretRight = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
    <path className={className} fill={color} d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>
export const CaretUp = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path className={className} fill={color} d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/></svg>
export const Caret = ({direction, color="black", className, onClick=()=>{} }) => {
    switch (direction) {
        case 'up':
            return <CaretUp color={color} className={className} onClick={onClick} />
        case 'down':
            return <CaretDown color={color} className={className} onClick={onClick} />
        case 'left':
            return <CaretLeft color={color} className={className} onClick={onClick} />
        case 'right':
            return <CaretRight color={color} className={className} onClick={onClick} />
        default:
            break;
    }
}
export const Sun = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path className={className} fill={color} d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM352 256c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zm32 0c0-70.7-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128s128-57.3 128-128z"/></svg>
export const Moon = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path className={className} fill={color} d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"/></svg>
export const ArrowUpRightFromSquare = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 512 512">
    <path className={className} fill={color} d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg>
export const Xmark = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path className={className} fill={color} d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
export const Trash = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path className={className} fill={color} d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
export const Envelope = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path className={className} fill={color} d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
export const Settings = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
    <path className={className} fill={color} d="M19.5,12c0-0.23-0.01-0.45-0.03-0.68l1.86-1.41c0.4-0.3,0.51-0.86,0.26-1.3l-1.87-3.23c-0.25-0.44-0.79-0.62-1.25-0.42 l-2.15,0.91c-0.37-0.26-0.76-0.49-1.17-0.68l-0.29-2.31C14.8,2.38,14.37,2,13.87,2h-3.73C9.63,2,9.2,2.38,9.14,2.88L8.85,5.19 c-0.41,0.19-0.8,0.42-1.17,0.68L5.53,4.96c-0.46-0.2-1-0.02-1.25,0.42L2.41,8.62c-0.25,0.44-0.14,0.99,0.26,1.3l1.86,1.41 C4.51,11.55,4.5,11.77,4.5,12s0.01,0.45,0.03,0.68l-1.86,1.41c-0.4,0.3-0.51,0.86-0.26,1.3l1.87,3.23c0.25,0.44,0.79,0.62,1.25,0.42 l2.15-0.91c0.37,0.26,0.76,0.49,1.17,0.68l0.29,2.31C9.2,21.62,9.63,22,10.13,22h3.73c0.5,0,0.93-0.38,0.99-0.88l0.29-2.31 c0.41-0.19,0.8-0.42,1.17-0.68l2.15,0.91c0.46,0.2,1,0.02,1.25-0.42l1.87-3.23c0.25-0.44,0.14-0.99-0.26-1.3l-1.86-1.41 C19.49,12.45,19.5,12.23,19.5,12z M12.04,15.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S13.97,15.5,12.04,15.5z"/></svg>
export const PaperPlane = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path className={className} fill={color} d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
export const Tag = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path className={className} fill={color} d="M0 80V229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7H48C21.5 32 0 53.5 0 80zm112 96c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/></svg>
export const Print = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path className={className} fill={color} d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zm-16-88c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/></svg>

export const Grid1 = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" >
    <path className={className} fill={color} d="M25.6,257.1c0-38.7,1.4-77-0.5-115.8c-2.7-57.4,27.8-83,80.2-84.3c77-1.8,154.5-2.7,231.1,0c64.3,2.3,85.2,27.3,86.1,92.1
		c0.9,75.2-0.5,149.9,0.5,225.2c0.5,56.1-27.3,82-82,82c-77,0-154.1,1.4-231.5-0.5C42,454.4,26,435.7,25.1,366
		C25.1,330,25.6,293.5,25.6,257.1z"/></svg>
export const Grid2 = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path className={className} fill={color} d="M12.2,223.4c-6.5,0-11.9-5.2-11.9-11.7c0,0,0-0.1,0-0.1C0.2,156.3,0.4,101.1,0,45.9C0,36.2,2.3,32.6,12.6,32.7
        c55.2,0.4,110.5,0.4,165.7,0.1c9.8-0.1,13.1,3.1,13,13.1c-0.4,55.2-0.2,110.5-0.2,165.7c0,6.7,0.2,12.1-9.4,12
        C125.7,223.2,69.6,223.4,12.2,223.4z"/>
    <path className={className} fill={color} d="M435.8,222.7h-167c-6.5,0-11.9-5.2-11.9-11.7c0,0,0-0.1,0-0.1c-0.1-54.8,0.3-109.6-0.3-164.3c-0.1-11.6,4-14,14.6-13.9
        c53.9,0.5,107.8,0.5,161.7,0c11.6-0.1,15.3,3.3,15.2,15.1c-0.6,53.9-0.2,107.8-0.3,161.7c0,0.6,0,1.1,0,1.7
        C447.6,217.6,442.2,222.7,435.8,222.7z"/>
    <path className={className} fill={color} d="M183.2,479.4H8.4c-4.1,0-7.6-3.2-7.9-7.3c-0.1-1.6-0.2-3.2-0.2-4.8C0.2,412.5,0.5,357.7,0,302.9
        c-0.1-10.4,2.8-13.8,13.5-13.7c54.8,0.5,109.6,0.3,164.3,0.2c7.9,0,13.6,0.5,13.5,11.2c-0.4,57-0.2,114-0.2,171c0,0,0,0,0,0.1
        C191.1,475.9,187.5,479.4,183.2,479.4z"/>
    <path className={className} fill={color} d="M440.9,479.3H263.9c-3.5,0-6.4-2.6-6.7-6.1c-0.2-2-0.3-3.9-0.3-5.9c-0.1-54.8,0.2-109.6-0.2-164.3
        c-0.1-10.2,2.4-14,13.3-13.9c54.8,0.5,109.6,0.3,164.3,0.2c7.8,0,13.7,0.3,13.7,11c-0.4,57.5-0.2,114.9-0.3,172.4c0,0,0,0,0,0.1
        C447.6,476.4,444.5,479.3,440.9,479.3z"/></svg>
export const Grid3 = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path className={className} fill={color} d="M447.7,256.4c0-7.1,0.1-14.2,0-21.3c-0.6-26.1-16.8-42.1-42.9-42.2c-13.8-0.1-27.5-0.1-41.3,0
        c-25.2,0.2-41.7,16.1-42.5,41.4c-0.4,14.6-0.4,29.3,0,43.9c0.6,24,15.7,40.2,39.7,41.5c15.9,0.9,32,0.9,47.9,0
        c24-1.5,38.7-17.9,39.1-42C447.8,270.6,447.7,263.5,447.7,256.4z M0.3,95.4c0,7.6-0.2,15.1,0,22.7c0.6,23.3,15.6,39.8,38.7,41.1
        c16.4,0.9,33,0.9,49.4,0c22-1.3,37-15.8,38.2-37.3c1-17.7,0.8-35.6-0.4-53.3c-1.2-18.4-13.3-31.8-31.5-34.4
        c-13.5-2-27.4-1.9-41.2-2C13.9,32.1,0.3,45.6,0.3,84.8C0.3,88.3,0.3,91.9,0.3,95.4z M447.7,96.2c0-7.1,0.1-14.2,0-21.3
        c-0.4-23.1-12.3-38.6-35-41c-18.4-2-37.3-2.1-55.7-0.2c-23,2.3-35.4,17.4-36,40.3c-0.4,15.1-0.5,30.2,0,45.3
        c0.8,21.6,14.6,37.9,35.6,39.6c18.5,1.5,37.3,1.5,55.8,0c20.5-1.7,34.2-17.8,35.2-38.6C448,112.2,447.6,104.2,447.7,96.2z
        M64.4,192.8c-8,0-16-0.2-24,0c-22.1,0.7-39,16.7-39.9,38.7c-0.7,16.4-0.6,32.9,0,49.4c0.8,20.1,13.3,35.5,33.3,37.3
        c20.2,1.9,40.8,1.5,61.1-0.2c16.7-1.4,29.1-15.2,31.2-31.8c1.2-10.1,0.4-20.4,1.1-30.6C130.2,211.2,118.1,188.8,64.4,192.8z
        M63.7,480C63.7,479.9,63.7,479.9,63.7,480c8.5-0.1,16.9,0.3,25.4-0.2c19.4-1,35.4-14.5,36.9-33.9c1.5-19.9,1.4-40-0.2-59.9
        c-1.4-17.2-15.5-30.4-32.8-31.5c-18.6-1.2-37.3-1.6-55.9-0.2c-22.9,1.7-36,16.8-36.7,39.7c-0.4,15.1-0.5,30.3,0,45.4
        c0.7,24.3,17.4,40.2,42,40.6C49.5,480.1,56.6,480,63.7,480z M384,480C384,480,384,480,384,480c7.6,0,15.1,0.1,22.7-0.1
        c23.4-0.7,39.8-15.9,40.8-38.9c0.7-16.4,0.6-32.9,0-49.4c-0.8-20.2-14.6-35.5-34.9-37.1c-18.1-1.4-36.4-1.6-54.5-0.2
        c-23.5,1.9-36.5,17.1-37.2,40.7c-0.4,13.8-0.3,27.6,0,41.4c0.5,27.8,16.4,43.3,44.4,43.6C371.5,480,377.8,480,384,480z M287.2,96.8
        C287.2,96.8,287.2,96.8,287.2,96.8c0-6.7,0.1-13.4,0-20c-0.3-27.1-14.3-42.7-41-44.3c-14.6-0.9-29.4-0.9-44,0
        c-25,1.5-38.3,13.8-40.5,38.9c-1.5,17.1-1.1,34.6,0.2,51.8c1.6,20.9,15.1,34.5,36.1,36c17.3,1.2,34.7,1.2,52,0.1
        c22.6-1.5,36.8-18,37.2-41C287.3,111.1,287.2,103.9,287.2,96.8z M287.2,256.4C287.2,256.4,287.1,256.4,287.2,256.4
        c-0.1-8,0.1-16-0.1-24c-0.6-21.3-16.4-38.4-37.5-39.4c-16.9-0.8-33.9-0.7-50.7,0c-20.1,0.8-35.6,15.2-36.9,35.2
        c-1.1,18.6-1,37.3,0,55.9c1,18.4,13.3,32.4,31.4,34.1c18.9,1.8,38.3,2.4,57.2,0.8c23.5-1.9,36.2-17.6,36.6-41.2
        C287.3,270.7,287.2,263.5,287.2,256.4z M287.1,416c0-7.6,0.2-15.1,0-22.7c-0.6-21.8-13.8-37.4-35.8-39c-19-1.4-38.2-1.2-57.2,0.3
        c-18.1,1.4-31.1,15.3-32.2,33.5c-1.1,18.5-1.3,37.3-0.1,55.8c1.3,21,16.7,35.2,37.5,35.9c16.4,0.5,32.9,0.5,49.4,0
        c21.1-0.6,37.6-17.4,38.3-38.5C287.4,432.9,287.1,424.5,287.1,416z"/>
    <path className={className} fill={color} d="M447.7,256.4c0,7.1,0.1,14.2,0,21.3c-0.4,24.1-15.1,40.5-39.1,42c-15.9,1-32,0.9-47.9,0c-24-1.3-39.1-17.5-39.7-41.5
        c-0.4-14.6-0.4-29.3,0-43.9c0.8-25.3,17.3-41.1,42.5-41.4c13.8-0.1,27.5-0.1,41.3,0c26.1,0.2,42.4,16.1,42.9,42.2
        C447.8,242.1,447.7,249.2,447.7,256.4z"/>
    <path className={className} fill={color} d="M0.3,95.4c0-3.6,0-7.1,0-10.7c0-39.1,13.6-52.7,53.3-52.5c13.7,0.1,27.6,0,41.2,2c18.2,2.6,30.3,16,31.5,34.4
        c1.1,17.7,1.4,35.6,0.4,53.3c-1.2,21.5-16.2,36-38.2,37.3c-16.4,1-32.9,0.9-49.4,0C15.9,158,0.9,141.5,0.3,118.1
        C0.2,110.6,0.3,103,0.3,95.4z"/>
    <path className={className} fill={color} d="M447.7,96.2c0,8,0.3,16-0.1,24c-1,20.7-14.7,36.9-35.2,38.6c-18.5,1.5-37.3,1.5-55.8,0c-21-1.7-34.8-17.9-35.6-39.6
        c-0.6-15.1-0.5-30.2,0-45.3c0.6-22.9,13-38,36-40.3c18.4-1.9,37.3-1.8,55.7,0.2c22.7,2.4,34.6,17.9,35,41
        C447.8,82,447.7,89.1,447.7,96.2z"/>
    <path className={className} fill={color} d="M64.4,192.8c53.7-4,65.7,18.4,62.7,62.9c-0.7,10.2,0.1,20.5-1.1,30.6c-2,16.6-14.5,30.4-31.2,31.8
        c-20.3,1.6-40.9,2.1-61.1,0.2C13.8,316.4,1.3,301,0.5,281c-0.6-16.4-0.7-32.9,0-49.4c0.9-22,17.9-38,39.9-38.7
        C48.4,192.6,56.4,192.8,64.4,192.8z"/>
    <path className={className} fill={color} d="M63.7,480c-7.1,0-14.2,0.1-21.4,0c-24.6-0.4-41.2-16.2-42-40.6C0,424.3,0,409.1,0.4,394c0.7-22.9,13.8-38,36.7-39.7
        c18.5-1.4,37.3-1,55.9,0.2c17.3,1.1,31.5,14.3,32.8,31.5c1.6,19.9,1.7,40,0.2,59.9c-1.4,19.4-17.5,32.9-36.9,33.9
        C80.6,480.2,72.2,479.9,63.7,480C63.7,479.9,63.7,479.9,63.7,480z"/>
    <path className={className} fill={color} d="M384,480c-6.2,0-12.5,0.1-18.7,0c-28.1-0.3-44-15.8-44.4-43.6c-0.2-13.8-0.3-27.6,0-41.4c0.6-23.6,13.7-38.7,37.2-40.7
        c18.1-1.5,36.4-1.2,54.5,0.2c20.3,1.6,34.1,16.9,34.9,37.1c0.7,16.4,0.7,32.9,0,49.4c-1,23-17.4,38.3-40.8,38.9
        C399.1,480.1,391.6,479.9,384,480C384,480,384,480,384,480z"/>
    <path className={className} fill={color} d="M287.2,96.8c0,7.1,0.1,14.2,0,21.4c-0.5,23-14.7,39.4-37.2,41c-17.3,1.2-34.7,1.2-52-0.1c-20.9-1.5-34.5-15-36.1-36
        c-1.3-17.2-1.8-34.7-0.2-51.8c2.2-25.1,15.5-37.3,40.5-38.9c14.6-0.9,29.4-0.9,44,0c26.8,1.6,40.7,17.2,41,44.3
        C287.3,83.5,287.2,90.1,287.2,96.8C287.2,96.8,287.2,96.8,287.2,96.8z"/>
    <path className={className} fill={color} d="M287.2,256.4c0,7.1,0.1,14.2,0,21.4c-0.4,23.6-13.1,39.3-36.6,41.2c-19,1.5-38.3,1-57.2-0.8c-18.2-1.8-30.5-15.7-31.4-34.1
        c-1-18.6-1.1-37.3,0-55.9c1.2-20,16.7-34.4,36.9-35.2c16.9-0.7,33.9-0.7,50.7,0c21.1,0.9,37,18.1,37.5,39.4
        C287.3,240.4,287.1,248.4,287.2,256.4C287.1,256.4,287.2,256.4,287.2,256.4z"/>
    <path className={className} fill={color} d="M287.1,416c0,8.4,0.2,16.9-0.1,25.3c-0.8,21.1-17.2,37.8-38.3,38.5c-16.4,0.5-32.9,0.5-49.4,0
        c-20.8-0.7-36.2-14.9-37.5-35.9c-1.2-18.5-1-37.3,0.1-55.8c1.1-18.2,14.1-32.1,32.2-33.5c19-1.5,38.2-1.7,57.2-0.3
        c22,1.6,35.2,17.1,35.8,39C287.3,400.9,287.1,408.5,287.1,416z"/></svg>
export const Grid4 = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path className={className} fill={color} d="M44.6,272.1c8,0,16-0.1,24,0c14.3,0.2,19.4,4.2,20.1,18.8c0.9,16.8,0.7,33.7,0.2,50.6c-0.4,11.9-6.6,18.9-19.4,18.7
        c-16.9-0.2-33.7-0.1-50.6-0.1c-12.7,0-18.6-6.8-18.7-19.1c-0.1-16.9-0.3-33.7,0.1-50.6c0.3-13.3,5.4-18,19-18.3
        C27.7,272,36.1,272.1,44.6,272.1z"/>
    <path className={className} fill={color} d="M44,479.5c-8.9,0-17.8,0-26.7,0c-11-0.1-16.6-6.2-16.9-16.6c-0.5-18.2-0.4-36.5,0.1-54.8c0.3-10.4,5.8-16.3,17-16.1
        c17.8,0.3,35.6,0.4,53.5,0c12.1-0.3,17.5,6.5,17.9,17c0.6,17.8,0.2,35.6-0.3,53.4c-0.4,11.9-7.1,17.5-19.1,17.2
        C60.9,479.3,52.4,479.5,44,479.5z"/>
    <path className={className} fill={color} d="M43.3,239.9c-8.4,0-16.9,0-25.3,0c-11.4,0-17.5-5.8-17.7-17.1c-0.3-17.3,0-34.6-0.1-52c-0.1-12.2,6.7-18.2,18-18.5
        c17.8-0.5,35.5-0.5,53.3,0c10.4,0.3,16.7,6.4,17,16.8c0.5,18.2,0.7,36.4,0,54.6c-0.4,11.1-7.4,16.5-18.6,16.3
        C61,239.8,52.2,239.9,43.3,239.9z"/>
    <path className={className} fill={color} d="M44.4,120.3c-9.4,0-18.7,0.3-28.1-0.1c-10.1-0.4-15.8-6.1-15.9-16.1C0,85.9,0.1,67.6,0.3,49.4c0.1-9.9,5.2-16.3,15.4-16.5
        c19.1-0.3,38.3-0.1,57.4,0.2c8.9,0.1,14.7,4.9,14.9,13.8c0.5,19.5,0.6,39.1,0,58.6c-0.3,10-7,14.9-17,14.9c-8.9,0-17.8,0-26.7,0
        C44.4,120.3,44.4,120.3,44.4,120.3z"/>
    <path className={className} fill={color} d="M240.7,315.6c0-8.9,0.1-17.8,0-26.7c-0.2-11.1,5.2-16.6,16.1-16.7c18.3-0.1,36.5-0.1,54.8,0c10.9,0.1,16.3,6,16.3,16.8
        c0.1,18.3,0.1,36.5,0,54.8c-0.1,10.2-5.7,15.9-15.7,16.1c-18.7,0.4-37.4,0.5-56.1,0.1c-10-0.2-15.4-5.9-15.3-16.3
        C240.7,334.3,240.7,324.9,240.7,315.6z"/>
    <path className={className} fill={color} d="M403.5,272.1c9.3,0,18.7,0,28,0c10.3,0,15.9,5.6,16.2,15.5c0.5,18.6,0.5,37.3,0,55.9c-0.3,10.3-6.1,16.5-16.9,16.5
        c-17.8,0.1-35.5,0.2-53.3,0c-11.1-0.1-17.7-5.9-17.9-17.2c-0.4-17.8-0.5-35.5,0-53.3c0.3-11.5,6.3-18,18.6-17.6
        C386.6,272.4,395,272.1,403.5,272.1z"/>
    <path className={className} fill={color} d="M240.7,435.5c0-9.3,0-18.6,0-28c0.1-9.7,5.3-15,14.8-15.2c19.1-0.4,38.2-0.3,57.3-0.2c9.8,0.1,14.8,5.9,14.9,15.1
        c0.4,19.1,0.4,38.2,0,57.3c-0.2,9.4-6,14.7-15.4,14.8c-18.6,0.3-37.3,0.3-55.9,0c-10-0.2-15.6-5.4-15.6-15.9
        C240.7,454.2,240.7,444.8,240.7,435.5z"/>
    <path className={className} fill={color} d="M404,392.1c8.9,0,17.8,0.1,26.7,0c11.1-0.2,16.8,5.6,17,16.1c0.4,18.3,0.4,36.5,0,54.8c-0.2,10.5-5.9,16.5-16.8,16.5
        c-17.8,0-35.6-0.1-53.5,0.1c-11.7,0.1-17.5-6.2-17.8-17.1c-0.5-17.8-0.5-35.6,0-53.4c0.3-11.8,6.8-17.7,19-17.1
        C387,392.4,395.5,392.1,404,392.1z"/>
    <path className={className} fill={color} d="M240.7,77c0-9.3,0.1-18.7,0-28c-0.1-9.5,3.9-15.6,13.9-15.8c19.5-0.3,39.1-0.6,58.6-0.2c10.2,0.2,14.8,6.7,14.7,16.8
        c-0.1,17.8,0,35.5,0,53.3c0,10.8-5.8,16.8-16.3,17c-18.7,0.4-37.3,0.5-56-0.1c-9.9-0.3-15.2-6-14.9-16.5
        C240.9,94.8,240.7,85.9,240.7,77z"/>
    <path className={className} fill={color} d="M447.8,75.6c0,8.9,0.1,17.8,0,26.7c-0.2,11.8-6.3,18.1-18.3,18.1c-17.4,0-34.7,0-52.1,0c-11.4,0-17.6-6.2-17.9-17.2
        c-0.5-17.8-0.4-35.6-0.1-53.4c0.2-11.3,6.5-17.2,17.7-17.3c17.4-0.2,34.7-0.1,52.1-0.1c12.6,0,18.7,6.6,18.7,19.1
        C447.8,59.6,447.8,67.6,447.8,75.6z"/>
    <path className={className} fill={color} d="M240.7,196.1c0-8.5,0.3-16.9-0.1-25.4c-0.6-12.6,6.1-18.2,17.6-18.5c16.9-0.4,33.9-0.6,50.7,0c14.1,0.5,18.7,6,18.9,20.2
        c0.2,16.5-0.1,32.9,0.1,49.4c0.1,12.3-6,18-18,18c-16.9,0-33.8,0.3-50.8-0.1c-14.8-0.3-18.3-4.4-18.5-19.7
        C240.6,212.1,240.7,204.1,240.7,196.1z"/>
    <path className={className} fill={color} d="M403.3,239.8c-8.9,0-17.8,0-26.6,0c-10.9,0-16.8-5.9-17.1-16.2c-0.5-18.2-0.3-36.4,0-54.6c0.2-10.8,7.3-16.3,17.2-16.6
        c17.7-0.6,35.5-0.6,53.3,0c11.5,0.3,17.8,6.9,17.8,18.8c0,16.9,0.1,33.7,0,50.6c-0.1,11.7-5.5,18.3-17.9,18.2
        C421.1,239.7,412.2,239.8,403.3,239.8C403.3,239.8,403.3,239.8,403.3,239.8z"/>
    <path className={className} fill={color} d="M207.4,436c0,8.9-0.2,17.8,0,26.7c0.3,11.2-5.6,16.5-16.2,16.7c-18.2,0.3-36.5,0.2-54.7,0c-9.6-0.1-15.3-5.7-15.5-14.9
        c-0.5-19.6-0.5-39.2,0.1-58.7c0.3-8.7,5.7-13.7,14.9-13.7c18.7,0,37.4-0.1,56.1,0c10.4,0,15.4,5.5,15.3,15.9
        C207.4,417.3,207.4,426.7,207.4,436z"/>
    <path className={className} fill={color} d="M207.4,314.9c0,9.8,0,19.6,0,29.4c0,9.7-5.2,15.2-14.7,15.4c-18.7,0.4-37.4,0.3-56.1,0.1c-10.3-0.1-15.6-6.4-15.8-16.2
        c-0.4-18.7-0.4-37.4,0-56.1c0.2-10,6.1-15.4,16.4-15.4c18.3,0,36.5-0.2,54.8,0.1c10,0.2,15.6,5.4,15.4,16
        C207.3,297.1,207.4,306,207.4,314.9z"/>
    <path className={className} fill={color} d="M207.5,76.6c0,8.9-0.2,17.8,0,26.7c0.2,11-5.1,16.8-15.9,17c-17.8,0.2-35.6,0.2-53.4,0c-11.2-0.1-17.4-5.8-17.4-17.4
        c0-17.8,0-35.6,0-53.4c0-10.9,5.7-16.6,16.3-16.8c17.8-0.3,35.6-0.4,53.4-0.1c11.8,0.2,17.9,6.1,17,18.6
        C207,59.7,207.5,68.2,207.5,76.6z"/>
    <path className={className} fill={color} d="M207.5,197c0,8.9-0.1,17.8,0,26.7c0.2,10.6-5.5,15.8-15.4,16c-18.3,0.4-36.5,0.2-54.8,0.1c-10.1-0.1-16.1-5.5-16.3-15.6
        c-0.4-18.7-0.2-37.4,0-56.1c0.1-10.7,7.2-15.2,16.4-15.6c17.8-0.7,35.6-0.7,53.4-0.1c11.7,0.4,17.6,6.9,16.7,19.2
        C207,180,207.5,188.5,207.5,197z"/></svg>




export const QuoteLeft = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" >
    <path className={className} fill={color} d="M0,216C0,149.7,53.7,96,120,96h7.3c18,0,33.1,14.7,32.7,32.7c-0.4,17.4-14.5,31.3-32,31.3h-8c-30.9,0-56,25.1-56,56v8h113.4
	c8.1,0,14.6,6.5,14.6,14.6v162.7c0,8.1-6.5,14.6-14.6,14.6H14.6C6.5,416,0,409.5,0,401.4V320v-32V216z M256,216
	c0-66.3,53.7-120,120-120h7.3c18,0,33.1,14.7,32.7,32.7c-0.4,17.4-14.5,31.3-32,31.3h-8c-30.9,0-56,25.1-56,56v8h113.8
	c7.8,0,14.2,6.3,14.2,14.2v163.7c0,7.8-6.3,14.2-14.2,14.2H270.2c-7.8,0-14.2-6.3-14.2-14.2V320v-32V216z"/></svg>
export const QuoteRight = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" >
    <path className={className} fill={color} d="M448,315.7c0,55.4-44.9,100.3-100.3,100.3h-37.9c-3,0-7.3-1.8-9.4-4c-25.9-27.6-7-60,19.7-60h27.7
    c20.1,0,36.3-16.3,36.3-36.3V288H270.4c-8,0-14.4-6.4-14.4-14.4V110.4c0-8,6.4-14.4,14.4-14.4h163.2c8,0,14.4,6.4,14.4,14.4V192v32
    V315.7z M192,315.7C192,371.1,147.1,416,91.7,416H53.7c-3,0-7.3-1.8-9.4-4c-25.9-27.6-7-60,19.7-60h27.7c20.1,0,36.3-16.3,36.3-36.3
    V288H14.2C6.3,288,0,281.7,0,273.8V110.2C0,102.3,6.3,96,14.2,96h163.7c7.8,0,14.2,6.3,14.2,14.2V192v32V315.7z"/></svg>
export const BadgeCheck = ({color="black", className, onClick=()=>{} }) => <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path className={className} fill={color} d="M317.3,435c-15.4-7.3-24.2,4.2-35.5,16.5c-33.1,36.2-92.1,32.2-122-7c-6-7.9-10.7-11.4-22.1-10.1
    C79.3,441,37.2,395.5,46,337.5c0.6-4-2.2-10.3-5.5-12.8c-49.3-38.1-49.2-99.6,0.3-137.4c3.2-2.5,5.7-9,5-13
    C36.2,115.8,83,68.7,141.5,78.6c6.4,1.1,10.4-0.2,14.5-5.8c36-49.4,100.1-49.6,135.7-0.3c4.4,6.1,8.6,7.2,15.4,6
    c57.1-10.1,105.5,38.6,94.6,95.4c-1.3,6.8,0,10.2,5.5,14.2c49.4,35.8,49.6,99.3,0.5,135.9c-5.1,3.8-7.6,6.8-6.3,13.9
    C411.4,390.4,373.8,435.1,317.3,435z M196.3,282.7c-12.2-13.7-18.9-21.3-30.8-32.6c-19.1-22.9-53.9,5.7-31.6,28.7
    c83.1,88,52.6,63.4,172.1-47c23.6-21.5-7.9-53-30.1-30.4C248.1,228.2,224.6,253.9,196.3,282.7z"/></svg>










// https://prod-streaming-video-msn-com.akamaized.net/4b19dc45-879f-41df-aa0d-5ec8142b0962/e48d3d3f-d29b-4a66-ab78-d7c38e269435.mp4

// https://meyerweb.com/eric/tools/dencoder/
// cht=qr&chl=
// BEGIN:VCARD
// VERSION:3.0
// N:Frianeza;Kaye
// FN:Kaye Frianeza
// ORG:Knots Of Love
// ADR:;;;Ajax;ON;L1S 2H8;Canada
// TEL;WORK;VOICE:
// TEL;CELL:6477841890
// TEL;FAX:
// EMAIL;WORK;INTERNET:knotsoflove.kaye@gmail.com
// URL:https://knotsoflove.to
// END:VCARD
// &chs=180x180&choe=UTF-8&chld=L|2 */







